import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Hero from '../components/landing/Hero';
import Wrapper from '../components/shared/Wrapper';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t('shared.appName')}</title>
        <link rel="canonical" href="https://rxresu.me/" />
      </Helmet>

      <div className="container px-8 xl:px-0 text-center md:text-left mt-24 md:pt-16">
        <Hero />
      </div>
    </Wrapper>
  );
};

export default memo(Home);
